$color--pale: #ffffff;
$color--dark: #000000;
$color--dark-opacity: rgba(0, 0, 0, 0.2);
$color--pale-opacity: rgba(255, 255, 255, 0.2);

$color--sea-wave: #3fc1c0;

$text-shadow: $color--dark 1px 1px 5px;

// // %btn--standart-circle
$btn--standart-circle__size: 40px;
$btn--standart-circle__fill: $color--dark-opacity;

:root {
  --color-pale: #ffffff;
}
