.y-scroll-off {
  overflow-y: hidden;
}

.visually-hidden,
%visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0px;
  padding: 0px;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
