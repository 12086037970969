@import './slick-slider/slick';
@import './buttons/double-arrow';

.client-slider {
  position: relative;

  &__arrow {
    position: absolute;
    z-index: 1;
    top: 50%;

    &--previous {
      left: 25px;
      transform: translateY(-50%) rotate(180deg);
    }

    &--next {
      right: 25px;
      transform: translateY(-50%);
    }
  }

  &__slide {
    position: relative;
    height: 100vh !important;
    display: block !important;
  }

  &__picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__textarea {
    .slick-current &{
      transform:  translateX(0);
      opacity: 1;
      // transform:  scale(1,1);
    }

    position: absolute;
    z-index: 1;
    bottom: 60px;
    right:0;
    // transform:  scale(0,1);
    transform:  translateX(100%);
    transform-origin: center right;

    opacity: 0;
    
    transition-property: transform, opacity;
    transition-duration: 500ms;
    transition-timing-function: linear;
    // transition-delay: 1000ms;

    margin: 0;
    padding: 10px;

    color: $color--pale;
    background-color: $color--dark-opacity;
    
  }

  &__menu{
       background-color: $color--dark-opacity;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
        margin-top: -60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__toggle-btn {
    @extend %btn;
    @extend %btn--standart-circle;
    
    &.is-open{
          z-index: 1;
    }
  }

  &__icon-hum{
    padding-top: 3px;
      cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

    & .line{
      
      fill:none;
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
      stroke: $color--pale;
      stroke-width: 9.5px;
      stroke-linecap:round;

    }
    & .top {
  stroke-dasharray: 40 172;
}
& .middle {
  stroke-dasharray: 40 111;
}
& .bottom {
  stroke-dasharray: 40 172;
}
.is-open & .top {
  stroke-dashoffset: -132px;
}
.is-open & .middle {
  stroke-dashoffset: -71px;
}
.is-open & .bottom {
  stroke-dashoffset: -132px;
}

  }

    &__toggle-container {
    pointer-events: none;

    // display: block;
    opacity: 0;

    // position: absolute;
    // z-index: 91;
    // left: 0;
    // bottom: 0;

    // width: 100%;
    // height: 100vh;

    // background-color: $color--dark;

     width: 100%;
          height: 100%;
          position: fixed;
          bottom: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.8);

    transition: opacity 250ms ease-in-out;

    &.is-open {
      pointer-events: initial;
      opacity: 1;
          
          // background: #ed143de0;
         
    }
  }
}
