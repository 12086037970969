.company-info {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction:column;
  align-items: center;

  padding: 25px 50px;
  color: var(--color-pale);

  html.js &{
    flex-direction:row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__logo {
    width: 160px;
  }

  &__title, &__adress {
    @extend %visually-hidden;

  }

  &__contacts {    
    text-align: center;
    padding-top: 25px;
  }

  &__contacts-togge-btn{    
      display: none;
      top: 5px;
      fill: $color--pale;
     html.js &{
       display: block;
     }
}

.contacts__icon--open,
.contacts__icon--close{
  opacity: 0.7;
}
.contacts__icon--close,
.is-open .contacts__icon--open{
  display: none;
}
.contacts__icon--open,
.is-open .contacts__icon--close
 {
  display: block;
}
.contacts__icon--close{
  transform-origin: center center;
  transform: rotate(-90deg);
}


.contacts__decore {
  color: var(--color-pale);
}

.contacts__telephone {
  color: inherit;
  white-space: nowrap;
}

  @media screen and (min-width: 520px) {
    &{  
       flex-direction: row-reverse;
       justify-content: space-between;
      }
    &__contacts{
        padding-top: 0;
        text-align: start;
      }

    html.js &__contacts-togge-btn{     
         display: none;
      }
  } 

  @media screen and (max-width: 519px) {
   
    html.js &__contacts{
      pointer-events: none;
      width: 100%;
      position: absolute;
      left: 0;
      top:0;

      padding: inherit;

      background-color: $color--dark-opacity;
      text-align: end;
      transform: translateY(0);
      opacity: 0;
    transition: transform 250ms ease-in-out 50ms,
                opacity 250ms ease-in-out 50ms;

      &.is-open{
      pointer-events: visible;
      transform: translateY(100%);
      opacity: 1;
    }
  }

  } 
  @media screen and (max-width: 359px) {
   
    html.js &{
     flex-direction: column;
     align-items: center;
    }

  } 

}

